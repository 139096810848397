import { IconButton, Typography, CircleCloseModalIcon } from 'design-system';

import { Box, Stack } from '../containers';
import { styled, useTheme } from '../theme';

import React, { useMemo } from 'react';
import { Modal } from '@mui/material';

/**
 * React component for a mobile modal with customisable content and appearance.
 *
 * @param open - Indicates if the modal is open
 * @param title - The title of the modal
 * @param onClose - Function to close the modal
 * @param children - The content of the modal
 * @param fixedHeaderContent - Content to be fixed at the top of the modal
 * @param fixedFooterContent - Content to be fixed at the bottom of the modal
 * @param closeButtonText - Text for the close button
 * @param forceMaxHeight - Indicates if the modal should have a maximum height, even if the content is smaller
 * @param hasDivider - Indicates if the modal should have a divider
 * @return The mobile modal component
 */
export const MobileModal: React.FC<{
  open: boolean;
  title?: string | React.ReactNode;
  onClose: () => void | Promise<void>;
  children: React.ReactNode;
  fixedHeaderContent?: React.ReactNode;
  fixedFooterContent?: React.ReactNode;
  closeButtonText?: string;
  forceMaxHeight?: boolean;
  hasDivider?: boolean;
  hasCloseIcon?: boolean;
}> = ({
  open,
  title,
  onClose,
  children,
  fixedFooterContent,
  closeButtonText,
  forceMaxHeight,
  hasCloseIcon = true,
}) => {
  const theme = useTheme();

  const fixedHeaderPaddingTop = useMemo(() => {
    // currently, we have 3 different top spacing options:
    // 1. without modal title - used in the accommodation modal
    // 2. with title but without close button text - used in the contacts modal
    // 3. with title and with close button text - used in the checklist modals
    if (!title) {
      return theme.spacing(9);
    }
    if (!closeButtonText) {
      return theme.spacing(4);
    }
    return theme.spacing(7);
  }, [theme, title, closeButtonText]);

  return (
    <Modal
      open={open}
      onClose={() => void onClose()}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
    >
      <ModalContentWrapper forceMaxHeight={forceMaxHeight}>
        <FixedHeaderWrapper sx={{ paddingTop: fixedHeaderPaddingTop }}>
          <CloseButtonWrapper>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              onClick={() => {
                void onClose();
              }}
              sx={{ cursor: 'pointer', paddingLeft: 2 }}
            >
              {closeButtonText && (
                <Typography variant="label_Figma" color={theme.palette.principal.grey70}>
                  {closeButtonText}
                </Typography>
              )}
              {hasCloseIcon && (
                <IconButton
                  data-testid="close-mobile-modal"
                  sx={{
                    p: 0,
                    color: 'background.paper',
                  }}
                >
                  <CircleCloseModalIcon sx={{ width: 40, height: 40 }} />
                </IconButton>
              )}
            </Stack>
          </CloseButtonWrapper>
          {title && (
            <Typography sx={{ flexGrow: 1, m: 0, mb: 1 }} variant="subHeader">
              {title}
            </Typography>
          )}
        </FixedHeaderWrapper>

        <ChildrenWrapper>{children}</ChildrenWrapper>

        {fixedFooterContent && (
          <FixedFooterWrapper hasFreeVerticalSpace={false}>
            {fixedFooterContent}
          </FixedFooterWrapper>
        )}
      </ModalContentWrapper>
    </Modal>
  );
};

interface ModalContentWrapperProps {
  forceMaxHeight?: boolean;
}

const ModalContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'relativeMarginTop' && prop !== 'forceMaxHeight',
})<ModalContentWrapperProps>(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  width: '100%',
  maxHeight: 'calc(100% - 16px)',
  overflowY: 'auto',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  display: 'flex',
  flexDirection: 'column',
}));

const FixedHeaderWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  padding: theme.spacing(4, 3, 0),
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
}));

const ChildrenWrapper = styled(Box)(({}) => ({
  flexGrow: 1,
}));

const FixedFooterWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasFreeVerticalSpace',
})<{
  hasFreeVerticalSpace: boolean;
}>(({ theme, hasFreeVerticalSpace }) => ({
  position: hasFreeVerticalSpace ? 'sticky' : 'initial',
  bottom: 0,
  padding: theme.spacing(2, 3, 2),
  backgroundColor: theme.palette.background.default,
  borderTop: '1px solid',
  borderTopColor: theme.palette.principal.grey30,
}));

const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));
